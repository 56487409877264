import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import App from './App';
import Activation from "./components/Activation";
import Error from './components/Error';
import ForgottenPassword from './components/ForgottenPassword';
import Logout from './components/Logout';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material';
import theme from './theme';

if (process.env.REACT_APP_SENTRY_DNS) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DNS,
        environment: process.env.REACT_APP_SENTRY_ENV_NAME,
        ignoreErrors: [
            'Request failed with status code 401',
            'Network request failed',
            'Failed to fetch',
            'NetworkError',
            'Network Error',
            'Request aborted',
            'timeout exceeded',
            'Request failed with status code 422',
            'Non-Error promise rejection captured',
            'Cannot redefine property: googletag',
            'u is null',
            'Cannot read properties of null (reading \'style\')',
            'ResizeObserver loop completed with undelivered notifications.',
            /feature named 'clickToLoad' was not found/,
            'Can\'t find variable: gmo',
        ],
        // https://silinfo.atlassian.net/browse/MCCMULTITE-647 - recaptcha hiba
        // https://silinfo.atlassian.net/browse/MCCMULTITE-666
        beforeSend(event, hint) {
        if (event.message && event.message.includes('recaptcha')) {
            return null;
        }
        const regex = /recaptcha\/releases/i;
        
        // ha a hiba a recaptcha-ból jön (ez szerepel a stackrace végén) nem küldi ki a sentry
        if (event.exception && event.exception.values) {
            const frames = event.exception.values[0].stacktrace?.frames;
            
            if (frames) {
                const lastFrame = frames[frames.length - 1];
                const errorLocation = lastFrame?.function || '';

                if (regex.test(errorLocation)) {
                    return null;
                }
            }
        }
            return event;
        },
    });
    Sentry.setTag('host', process.env.REACT_APP_SENTRY_HOST);
}

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <HelmetProvider>
            <ThemeProvider theme={theme}>
                <Router>
                    <Routes>
                        <Route path='/' element={<App />} />
                        <Route path='/logout' element={<Logout />} />
                        <Route
                            path='/forgotten-password/:token'
                            element={<ForgottenPassword />}
                        />
                        <Route
                            path='/activation/:token'
                            element={<Activation />}
                        />
                        <Route path='/*' element={<Error statusCode={404}/>}/>
                    </Routes>
                </Router>
            </ThemeProvider>
        </HelmetProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
